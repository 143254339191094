// @flow strict
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from '../../../Image/Image';

const AboutContentPhoto = () => {
  const data = useStaticQuery(graphql`query AboutContentPhoto {
            allCloudinaryMedia(filter: {public_id: {regex: "/about_photo$/"}}) {
              edges {
                node {
                  public_id
                  secure_url
                }
              }
            }
          }`,
  );
  const url = data.allCloudinaryMedia.edges[0].node.secure_url;
  return (
    <>
      <Image url={url} alt={'Aiste'}/>
    </>
  );
}

export default AboutContentPhoto;