// @flow strict
import React from 'react';
import styles from './AboutTitle.module.scss';

const AboutTitle = () => (
  <div className={styles.aboutTitle}>
    <h1 className={styles["aboutTitle__text"]}>About me</h1>
    <div className={styles["aboutTitle__line"]} />
  </div>
);

export default AboutTitle;