// @flow strict
import React from 'react';
import styles from './AboutContentText.module.scss';

const AboutContentText = () => {
  return (
    <div className={styles.aboutContentText}>
      <h1>
        Well hello there.<br/>
        Nice to meet you.
      </h1>

      <p>
        I am Aistė.<br/><br/>
        I am the graphic designer that you are looking to add to your team. I am the one who is not afraid to pitch the
        team the idea that is so “out there” that it just might work. I am the gal who mixes everyone still left in the
        office a mean mojito to motivate us with that “little” last minute change the client requested on a Friday
        evening AFTER work hours. I am the person, who is not afraid to challenge the client’s preconceptions. I am the
        student, who is not afraid to ask the “dumb” question, instead of sitting silently. I am the hard worker, who
        can sit through the weekend to finish any task that unexpectedly got dropped to my lap. I am the team player,
        who is present at the after party after the all - nighter we just had to pull to finish the project on
        time.<br/><br/>
        I am Aistė.<br/><br/>
        Well. What are you waiting for? Give me a call and let me know about yourself.<br/><br/>
      </p>

      <h2>
        Skills
      </h2>
      <ul className={styles["aboutContentText__skillsList"]}>
        <li>Logotypes & logobooks</li>
        <li>Editorial design and print media</li>
        <li>Web design & banners</li>
        <li>Visual identity/ brandbook</li>
        <li>Photo manipulations,</li>
        retouching, mockups
        <li>Software knowledge – PS, AI, ID</li>
      </ul>
    </div>
  );
}

export default AboutContentText;