// @flow strict
import React from 'react';
import styles from './AboutContent.module.scss';
import AboutContentText from './AboutContentText/AboutContentText';
import AboutContentPhoto from './AboutContentPhoto/AboutContentPhoto';

const AboutContent = () => {
  return (
    <div className={styles.aboutContent}>
      <div className={styles["aboutContent__column"]}>
        <AboutContentText/>
      </div>
      <div className={styles["aboutContent__column"]}>
        <AboutContentPhoto/>
      </div>
    </div>
  );
};

export default AboutContent;