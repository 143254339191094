// @flow strict
import React from 'react';
import AboutTitle from '../components/About/AboutTitle/AboutTitle';
import AboutContent from '../components/About/AboutContent/AboutContent';
import Layout from '../components/Layout/Layout';

const AboutPage = ({location: {pathname}}) => {
  return (
    <Layout pathname={pathname} incomingTitle={'About'}>
      <AboutTitle/>
      <AboutContent/>
    </Layout>
  );
};

export default AboutPage;
